<template>
  <div>
    <v-card>
      <v-card-title>
        Mitglieder
        <v-spacer></v-spacer>
        <!--
        <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Suchen"
            single-line
            hide-details
        ></v-text-field>-->
      </v-card-title>
      <v-data-table
          :headers="headers"
          :items="members"
          :options.sync="options"
          :server-items-length="totalUserCount"
          :loading="loading"
          :footer-props="{ 'items-per-page-options': itemsPerPageOptions }"
          disable-sort
      >
        <template v-slot:item.progress="{ item }">
          <div class="d-flex flex-row flex-wrap">
            <div v-for="course in item['homeDto']['courses']" class="mr-2">
              <!--
              <div v-text="course.name + ':'"></div>
              <div v-text="course.progress + '%'"></div>
              -->
              <v-progress-circular :value="course.progress" :color="course.color" rotate="-90"></v-progress-circular>
            </div>
          </div>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-btn small outlined class="mr-2" color="primary darken-2" @click="openDetails(item.user.id)">
            <v-icon left>mdi-dots-vertical</v-icon>
            Details
          </v-btn>
          <v-btn small outlined color="red darken-4" @click="loginAsUser(item.user.id)">
            <v-icon left>mdi-account-key-outline</v-icon>
            Einloggen
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import Vue from "vue";
import mitt from "mitt";
window.mitt = window.mitt || new mitt();

export default {
  name: "MemberTable",
  data: () => ({
    headers: [
      /*{
        text: "Name",
        align: "start",
        sortable: false,
        value: "username"
      }, */
      {
        text: "E-Mail",
        value: "user.email"
      },
      {
        text: "Vorname",
        value: "personalInfo.firstName",
      },
      {
        text: "Nachname",
        value: "personalInfo.lastName"
      },
      {
        text: "Telefonnummer",
        value: "personalInfo.phoneNumber"
      },
      {
        text: "Fortschritt (Einf, Selb, Umf, Karr, Fin, Ges)",
        value: "progress"
      },
      {
        text: "Aktionen",
        align: "end",
        value: "actions"
      }
    ],
    itemsPerPageOptions: [20, 50, 100],
    options: {},
    members: [],
    totalUserCount: 0,
    loading: true
  }),
  watch: {
    options: {
      handler() {
        this.fetchMembers();
      },
      deep: true
    }
  },
  mounted() {

  },
  methods: {
    fetchMembers() {
      let itemsPerPage = this.options["itemsPerPage"];
      let pageNumber = this.options["page"];
      this.loading = true;
      this.$store.dispatch("getRequest", ["members/page/" + itemsPerPage + "/" + pageNumber, this.fetchMembersCallback]);
    },
    fetchMembersCallback(response) {
      this.loading = false;
      let userCount = response["totalElements"];
      if (userCount > 0) {
        this.totalUserCount = userCount;
        this.members = response["members"];
      } else {
        this.totalUserCount = 0;
        this.members = [];
      }
    },
    openDetails(userId) {
      window.mitt.emit("openMemberDetails", userId);
    },
    loginAsUser(userId) {
      this.$store.dispatch("getRequest", ["login-as-user/" + userId, this.loginAsUserCallback]);
    },
    loginAsUserCallback(data) {
      let token = data["token"];
      if (token !== null && token !== undefined && token !== "" && token !== "null" && token !== "undefined") {
        Vue.$cookies.set("roy-spitzner-custom-token", token);
        location.reload()
      }
    }
  }
};
</script>

<style scoped>

</style>
<template>
  <div>
    <v-main>
      <v-container fluid class="mt-4">
        <div class="d-flex align-baseline mb-6">
          <div class="text-h4 mr-8">Mitglieder</div>
          <v-progress-circular size="70" color="#3c9b9c" value="100" rotate="-90" class="mr-4">
            <div class="black--text text-h6" style="opacity: 0">11</div>
          </v-progress-circular>
          <v-progress-circular size="70" color="#AB4642" value="100" rotate="-90" class="mr-4">
            <div class="black--text text-h6" style="opacity: 0">11</div>
          </v-progress-circular>
          <v-progress-circular size="70" color="#DC9656" value="100" rotate="-90" class="mr-4">
            <div class="black--text text-h6" style="opacity: 0">11</div>
          </v-progress-circular>
          <v-progress-circular size="70" color="#76b82a" value="100" rotate="-90" class="mr-4">
            <div class="black--text text-h6" style="opacity: 0">11</div>
          </v-progress-circular>
          <v-progress-circular size="70" color="#044380" value="100" rotate="-90" class="mr-4">
            <div class="black--text text-h6" style="opacity: 0">11</div>
          </v-progress-circular>
          <v-progress-circular size="70" color="#A26F97" value="100" rotate="-90" class="mr-4">
            <div class="black--text text-h6" style="opacity: 0">11</div>
          </v-progress-circular>
        </div>
        <!--<v-expansion-panels v-if="false" inset>
          <v-expansion-panel v-for="(member) in members" :key="member.id" @change="endCall">
            <v-expansion-panel-header>
              <div class="d-flex flex-wrap align-baseline">
                <div class="text-subtitle-1 mr-4">{{ member["firstName"] + " " + member["lastName"] }}</div>
                <v-chip class="" color="secondary" label outlined>Unkontaktiert</v-chip>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content style2="height: calc(80vh - 128px); background-color: lightblue">
              <div>
                <div class="d-flex align-baseline flex-wrap">
                  <div class="mr-2 mb-2">Tel:</div>
                  <div class="mr-2">{{ member["phoneNumber"] }}</div>
                  <v-btn v-if="!callInProgress" class="mr-2 mb-2" color="blue" dark @click="startCall(member)">
                    <v-icon left class="fix-button-icon-size">mdi-phone</v-icon>
                    Anrufen
                  </v-btn>
                  <v-btn v-if="callInProgress"
                         class="mr-2 mb-2" color="blue" dark
                         @click="endCall"
                  >
                    <v-icon left class="fix-button-icon-size">mdi-phone-hangup</v-icon>
                    Abbrechen
                  </v-btn>
                  <v-btn v-if="callInProgress" class="mr-2 mb-2" color="secondary" dark>
                    <v-icon left class="fix-button-icon-size">mdi-phone-missed</v-icon>
                    Keine Antwort
                  </v-btn>
                  <v-btn v-if="callInProgress" class="mr-2 mb-2" color="red" dark>
                    <v-icon left class="fix-button-icon-size">mdi-help</v-icon>
                    Ungültige Nummer
                  </v-btn>
                  <v-btn v-if="callInProgress" class="mr-2 mb-2" color="red" dark>
                    <v-icon left class="fix-button-icon-size">mdi-phone-remove</v-icon>
                    Kein Interesse
                  </v-btn>
                  <v-btn v-if="callInProgress" class="mr-2 mb-2" color="green" dark>
                    <v-icon left class="fix-button-icon-size">mdi-phone-check</v-icon>
                    Erfolg
                  </v-btn>
                </div>
                <div class="d-flex align-baseline flex-wrap mt-2">
                  <div class="mr-2 mb-2">E-Mail:</div>
                  <div class="mr-2">{{ member["emailAddress"] }}</div>
                  <v-btn class="mr-2 mb-2" color="secondary" dark>
                    <v-icon left class="fix-button-icon-size">mdi-pencil</v-icon>
                    Bearbeiten
                  </v-btn>
                  <v-btn class="mr-2 mb-2" color="blue" dark>
                    <v-icon left class="fix-button-icon-size">mdi-email</v-icon>
                    Versenden
                  </v-btn>
                  <v-btn class="mr-2 mb-2" color="green" dark>
                    <v-icon left class="fix-button-icon-size">mdi-email</v-icon>
                    E-Mail 1
                  </v-btn>
                </div>
                <v-btn outlined :to="{ name: 'MemberView', params: { userId: member.id } }">Öffnen</v-btn>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels> -->
        <MemberTable></MemberTable>
        <v-dialog v-model="memberDetailsOpen" fullscreen transition="dialog-bottom-transition">
          <MemberDetails :user-id="memberDetailsUserId"></MemberDetails>
        </v-dialog>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import MemberTable from "@/components/MemberTable";
import MemberDetails from "@/components/MemberDetails";
import mitt from "mitt";
window.mitt = window.mitt || new mitt();
export default {
  name: "MemberList",
  components: { MemberDetails, MemberTable },
  data: () => ({
    memberDetailsOpen: false,
    memberDetailsUserId: 0,
    callInProgress: false
  }),
  computed: {
    members() {
      return this.$store.state.applicationData["members"];
    }
  },
  mounted() {
    window.mitt.on("openMemberDetails", userId => {
      this.memberDetailsOpen = true
      this.memberDetailsUserId = userId
    })
    window.mitt.on("closeMemberDetails", () => {
      this.memberDetailsOpen = false
      this.memberDetailsUserId = 0
    })
  },
  methods: {
    startCall(contact) {
      this.callInProgress = true;
    },
    endCall() {
      this.callInProgress = false;
    }
  },
  beforeDestroy() {
    this.endCall();
  }
};
</script>

<style scoped>

</style>